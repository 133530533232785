import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar/Navbar";
import Navigation from "../components/Navigation/Navigation";
import {
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import ApplyNow from "../components/ApplyNow/ApplyNow";
import { Link } from "react-router-dom";
import { projects, projectGradients, buttonColors } from "../data/projects";

const Projects = () => {
  const slidesPerView = useBreakpointValue({
    base: 1,
    sm: 1,
    md: 2,
    lg: 2,
    xl: 3,
  });

  return (
    <>
      <Helmet>
        <title>Webeet.io | Projects</title>
      </Helmet>
      <Navbar path='/projects' />
      <Flex
        w='100%'
        flexDir='column'
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Flex
          flexDir='column'
          alignItems='start'
          gap={4}
          m={[6]}
          maxW={"600px"}
        >
          <Heading
            textTransform='uppercase'
            color='#185C81'
            fontWeight='black'
            textAlign={"center"}
            width='100%'
            mt={[8, 12, 16, 20, 24]}
          >
            Case Studies
          </Heading>
          <Text lineHeight={8} mt={8} fontSize='lg'>
            At webeet.io, our work is defined by real-world challenges and
            innovative solutions that deliver measurable results. Each use case
            showcases the creativity, technical expertise, and dedication of our
            team as we collaborate with clients to overcome complex industry
            challenges. Guided by experienced leaders, we leverage cutting-edge
            technologies and practical insights to bring transformative ideas to
            life.{" "}
          </Text>
          <Text lineHeight={8} mt={8} fontSize='lg'>
            From AI-powered tools to sophisticated web applications and secure
            platforms, our use cases highlight our ability to bridge the gap
            between vision and execution. These stories demonstrate how we help
            businesses innovate, scale, and succeed. Explore our use cases to
            see how webeet.io is shaping the future of technology—one impactful
            solution at a time.
          </Text>
        </Flex>

        <Text fontSize='md' fontWeight={"bold"} color='#185C81'>
          <ChevronLeftIcon />
          Swipe to see more projects
          <ChevronRightIcon />
        </Text>

        <Flex w='100%'>
          <Swiper
            slidesPerView={slidesPerView}
            pagination={{ clickable: true, dynamicBullets: true }}
            centeredSlides
            loop
            spaceBetween={20}
          >
            {projects.map((company, index) => (
              <SwiperSlide key={company.alt}>
                <Flex
                  bgGradient={projectGradients[index % projects.length]}
                  width='600px'
                  maxWidth='100%'
                  direction='column'
                  borderRadius={"40px"}
                  p={10}
                  h={["90%", "85%", "90%", "90%", "90%"]}
                >
                  <Flex
                    direction={"column"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    height={"100%"}
                  >
                    <Flex
                      direction='column'
                      maxWitdh='100%'
                      alignItems={"center"}
                    >
                      <Flex height='125px'>
                        <Image
                          maxWidth='125px'
                          src={company.image}
                          alt={company.alt}
                          fit={"contain"}
                          mb={6}
                        />
                      </Flex>
                      <Heading mb={8}>{company.title}</Heading>
                      <Text>{company.content[0]}</Text>
                    </Flex>
                  </Flex>
                  {company.link && (
                    <Flex direction={"row-reverse"}>
                      <Link to={`/project/${company.title}`}>
                        <Button
                          bg={buttonColors[index % projects.length]}
                          color='#333'
                          _hover={{ bg: "#185C81", color: "white" }}
                          _active={{ bg: "#185C81", color: "white" }}
                        >
                          See more
                        </Button>
                      </Link>
                    </Flex>
                  )}
                </Flex>
              </SwiperSlide>
            ))}
          </Swiper>
        </Flex>
        <ApplyNow />
      </Flex>
      <Navigation path='/projects' />
    </>
  );
};

export default Projects;
