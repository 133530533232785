import Navbar from "../components/Navbar/Navbar";
import Navigation from "../components/Navigation/Navigation";
import {
  Button,
  Flex,
  Heading,
  UnorderedList,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { applications } from "../data/applications";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const Application = () => {
  const { id } = useParams();

  console.log("ID", id);

  const app = applications.filter((app) => app.id === id)[0];

  console.log("App", app);

  return (
    <>
      <Helmet>
        <title>webeet.io | {app.job}</title>
      </Helmet>
      <Flex minHeight='100vh' flexDirection={"column"} minWidth={"100%"}>
        <Navbar path='/application' />
        <Flex flexDir='column' mt={10} mb='100px' px={6} alignItems='center'>
          <Flex w='100%' alignItems='center' mb={3} maxWidth='1000px'>
            {/* <Image
              mr={3}
              height={app.isImageWhite ? "40px" : "40px"}
              width={
                app.options?.imageWidth || (app.isImageWhite ? "60px" : "40px")
              }
              objectFit='contain'
              src={app.image}
              alt={app.company}
              p={app.isImageWhite ? [2, 2, 2, 2, 3] : ""}
              bg={app.isImageWhite ? "#333" : ""}
              borderRadius={app.isImageWhite ? "xl" : ""}
            /> */}
            <Heading fontWeight='thin' size='lg' display='flex'>
              <Text>
                <Text as='b'>{app.job} </Text>
                {/* {app.company && <Text as='thin'> | {app.company}</Text>} */}
              </Text>
            </Heading>
          </Flex>
          <Flex maxWidth='1000px' flexDir='column'>
            {app.content.map((section) => (
              <>
                <Heading
                  size='md'
                  textAlign='left'
                  w='100%'
                  mb={2}
                  mt={[4, 4, 6, 8, 10]}
                >
                  {section.title}
                </Heading>
                {section.text && (
                  <Text mb={section.bulletPoints ? 4 : 0}>{section.text}</Text>
                )}
                {section.bulletPoints && (
                  <UnorderedList spacing={2} alignItems={"center"}>
                    {section.bulletPoints.map((point) => (
                      <ListItem>{point}</ListItem>
                    ))}
                  </UnorderedList>
                )}
              </>
            ))}
            <Flex w={"100%"} mt={[10, 10, 10, 10, 10]}>
              <a
                href={app.applicationLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Button bg='#B9B0E8' color='white'>
                  Apply Now
                </Button>
              </a>
            </Flex>
          </Flex>
        </Flex>
        <Navigation path='/application' />
      </Flex>
    </>
  );
};

export default Application;
