import { andy, bertrand, eran, gali, michael } from "../assets/graduates";

export const graduates = [
  {
    name: "Eran Blank",
    position: "Software Engineer",
    company: "Rokka.ai",
    avatar: eran,
    content:
      "It’s all thanks to the incredible experience I had at webeet.io. Working on real-world projects with industry professionals pushed me to grow faster than I ever imagined. The mentorship was outstanding—my mentor was always available to guide me, answer questions, and challenge me to think critically. The hands-on experience made me feel confident and truly ready to step into my new role.",
    timeToJob: "2 months",
  },
  {
    name: "Gali Brill",
    position: "Backend Developer",
    company: "One Technologies",
    avatar: gali,
    content:
      "This program exceeded all my expectations. I landed a job that I love, and I owe it to the practical experience and incredible mentorship I received here. Collaborating with industry professionals taught me what it’s really like to work in tech, and the feedback I got helped me improve rapidly. I feel confident in my skills and excited about the future, thanks to this program.",
    timeToJob: "5 months",
  },
  {
    name: "Michael Korostylev",
    position: "Software Engineer",
    company: "Compie Technologies",
    avatar: michael,
    content:
      "I couldn’t be more grateful for the experience. The projects were challenging yet incredibly rewarding, and it felt amazing to work on solutions that had a real impact. The mentorship I received was second to none, and the skills I gained made me feel completely prepared to succeed in my new job. It’s an experience I’ll carry with me throughout my career.",
    timeToJob: "3 months",
  },
  {
    name: "Bertrand Flint",
    position: "Data Analyst",
    company: "Delivery Hero",
    avatar: bertrand,
    content:
      "Webeet program basically covers what I hoped an accelerator would be: to provide connections and hands-on experiences while providing further learning content and accompanying one with their job applications.",
    timeToJob: "1 month",
  },
  {
    name: "Andy Schunke",
    position: "Cloud Consultant",
    company: "Woodmark Consulting",
    avatar: andy,
    content:
      "I was always interested in cloud technologies, but I never imagined I could gain so much experience so quickly. At webeet.io, I had the opportunity to work on real cloud solutions while also learning alongside experienced mentors. During the program, I earned my AWS certification, which gave me the confidence to dive deeper into cloud infrastructure. I landed a job as a Cloud Consultant, and I couldn’t have done it without the support and opportunities provided by the studio. This program truly changed my career.",
    timeToJob: "2 months",
  },
];
