import Navbar from "../components/Navbar/Navbar";
import Navigation from "../components/Navigation/Navigation";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { david, anastasia, craig } from "../assets/leaders-simple";
import ApplyNow from "../components/ApplyNow/ApplyNow";

const Team = () => {
  const path = "/team";

  const leaders = [
    {
      name: "David Rajcher",
      title: "Founder, CEO & Head of Engineering",
      img: david,
      content: [
        "I bring close to 20 years of leadership and technical expertise.",
        "As a former Program Director for Software Engineering at Masterschool, I had the privilege of shaping the careers of hundreds of students, curating innovative learning paths, and delivering impactful education. My experience as Interim CTO at Mind Guard allowed me to lead the development of AI-driven productivity solutions, securing over $500,000 in funding.",
        "From mentoring engineers to overseeing large-scale technical transformations, I am passionate about leveraging my diverse background to guide webeet.io in delivering exceptional digital solutions to startups worldwide.",
        "At webeet.io, I focus on building the next generation of software, implementing cutting-edge technologies, and fostering a culture of innovation.",
      ],
    },
    {
      name: "Anastasiia Piterskaia",
      title: "Head of Data",
      img: anastasia,
      content: [
        "I am a seasoned data professional with over seven years of experience in analytics and business intelligence.",
        "I’ve led data teams at renowned gaming companies like Playrix and Plarium, where I developed advanced segmentation models, automated key reporting systems, and optimized customer retention strategies.",
        "My passion for education has driven me to mentor aspiring data analysts and develop courses at Masterschool, helping students master essential skills.",
        "At webeet.io, I focus on crafting data-driven solutions that empower startups to make smarter, faster decisions.",
      ],
    },
    {
      name: "Craig Taylor",
      title: "Head of Cyber Security",
      img: craig,
      content: [
        "I am a cybersecurity expert with a career spanning over 15 years in military and private sector roles.",
        "As a Technical Lead for the Royal Air Force’s Distributed Cyber Protection Team, I managed incident response and cyber defense strategies while mentoring future security specialists.",
        "My work as a consultant and instructor has allowed me to teach advanced cybersecurity frameworks like ISO 27001 and NIST to professionals worldwide.",
        "At webeet.io, I apply my depth of expertise to create robust, secure systems tailored to client needs, ensuring startups thrive in today’s rapidly evolving threat landscape.",
      ],
    },
  ];

  return (
    <>
      <Navbar path={path} />
      <Flex maxW='100%' justifyContent={"center"}>
        <Flex
          flexDir='column'
          alignItems='start'
          gap={4}
          m={[10]}
          maxW={"600px"}
        >
          <Heading
            textTransform='uppercase'
            color='#185C81'
            fontWeight='black'
            textAlign={"center"}
            width='100%'
            mt={[8, 12, 16, 20, 24]}
          >
            Team Leaders
          </Heading>
          {leaders.map((leader) => (
            <>
              <Flex w='100%' justifyContent='center' alignItems='center'>
                <Image
                  src={leader.img}
                  alt='founder'
                  mt={[4, 6, 8, 10, 12]}
                  borderRadius={"100%"}
                  w={["50%", "40%", "30%", "25%", "25%"]}
                />
              </Flex>
              <Box w='100%'>
                <Text
                  lineHeight={6}
                  fontSize='lg'
                  fontWeight={"bold"}
                  textAlign={"center"}
                >
                  {leader.name}
                </Text>
                <Text
                  lineHeight={6}
                  fontSize='lg'
                  fontWeight={"bold"}
                  textAlign={"center"}
                >
                  {leader.title}
                </Text>
              </Box>
              {leader.content.map((paragraph) => (
                <Text lineHeight={8} fontSize='lg'>
                  {paragraph}
                </Text>
              ))}
            </>
          ))}
          <ApplyNow />
        </Flex>
      </Flex>
      <Navigation path={path} />
    </>
  );
};

export default Team;
