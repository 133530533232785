import { SimpleGrid, Flex, Heading, Text, Image } from "@chakra-ui/react";
import { WebeetMain } from "../../assets/layout";

const Hero = ({ endpoint }) => {
  return (
    <SimpleGrid
      mx={[6, 6, 8, 10, 12]}
      my={12}
      as='section'
      columns={[1, 1, 2, 2, 2]}
      justifyItems='end'
      maxW={["100%", "1200px"]}
      gap={[8, 4]}
    >
      <Flex
        flexDir='column'
        justifyContent={endpoint === "student" ? "center" : "start"}
        alignItems='start'
        gap={4}
      >
        <Heading
          mt={[0, 0, 100]}
          textTransform='uppercase'
          color='#185C81'
          fontWeight='black'
        >
          Expert Digital Services, Startup-Friendly Pricing
        </Heading>
        <Text lineHeight={8} fontSize='lg'>
          At webeet.io, we specialize in delivering sophisticated digital
          solutions tailored to the unique needs of startups. Our expertise
          spans superior web development, insightful data analytics,
          cutting-edge AI integration, and robust cybersecurity. We’re dedicated
          to providing top-tier services that empower businesses to innovate,
          scale, and thrive—without breaking the bank.
        </Text>
        <Text lineHeight={8} fontSize='lg'>
          Whether you’re building your first product, optimizing operations, or
          enhancing security, we bring the tools and talent needed to turn your
          vision into reality. With our startup-friendly pricing and commitment
          to excellence, your success story begins here. Let us help you take
          your business to new heights.
        </Text>
      </Flex>
      <Flex w='100%' justifyContent='center' alignItems='center'>
        <Image src={WebeetMain} alt='illustration' />
      </Flex>
    </SimpleGrid>
  );
};

export default Hero;
