import {
  Flex,
  Image,
  Button,
  List,
  ListItem,
  Show,
  Hide,
  IconButton,
  Box,
} from "@chakra-ui/react";
import logo from "../../assets/logos/logo.png";
import { Link } from "react-router-dom";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

export const Navbar = ({ path }) => {
  return (
    <Flex
      as='nav'
      padding='1rem'
      position='sticky'
      top={4}
      left={0}
      zIndex={999}
      width='1200px'
      maxW='100%'
      mx='auto'
      alignItems='center'
      justifyContent='space-between'
      boxShadow='md'
      backdropFilter='blur(7.5px)'
      borderRadius='15px'
      px={8}
    >
      <Box
        _hover={{ cursor: "pointer" }}
        onClick={(e) => {
          e.preventDefault(); // Prevent the default anchor behavior
          window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll smoothly to the top
        }}
      >
        <Image
          src={logo}
          alt='logo'
          boxSize={["35px", "50px"]}
          objectFit='contain'
        />
      </Box>
      <Flex>
        <Hide below='md'>
          <Show above='sm'>
            <List
              display='flex'
              alignItems={"center"}
              gap={4}
              mx={4}
              color='#402F9D'
              fontSize={"md"}
            >
              <ListItem
                _hover={{ textDecoration: "underline" }}
                textDecoration={path === "/" ? "underline" : "none"}
              >
                <Link to='/'>Home</Link>
              </ListItem>
              <ListItem
                _hover={{ textDecoration: "underline" }}
                textDecoration={path === "/team" ? "underline" : "none"}
              >
                <Link to='/team'>Team</Link>
              </ListItem>
              <ListItem
                _hover={{ textDecoration: "underline" }}
                textDecoration={path === "/projects" ? "underline" : "none"}
              >
                <Link to='/projects'>Case Studies</Link>
              </ListItem>
            </List>
            <a
              href='https://form.typeform.com/to/rQCjNxVX'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button
                bgColor={path === "/apply" ? "#B2D7CE" : "#B9B0E8"}
                color='white'
              >
                Let's Collaborate
              </Button>
            </a>
          </Show>
        </Hide>
        <Show below='md'>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label='Options'
              icon={<HamburgerIcon />}
              variant='outline'
            />
            <MenuList>
              <Link to='/'>
                <MenuItem>Home</MenuItem>
              </Link>
              <Link to='/team'>
                <MenuItem>Team</MenuItem>
              </Link>
              <Link to='/projects'>
                <MenuItem>Projects</MenuItem>
              </Link>
              <MenuDivider />
              <a
                href='https://form.typeform.com/to/rQCjNxVX'
                target='_blank'
                rel='noopener noreferrer'
              >
                <MenuItem>
                  <Button
                    width='100%'
                    bgColor={path === "/apply" ? "#B2D7CE" : "#B9B0E8"}
                    color='white'
                  >
                    LET'S COLLABORATE
                  </Button>
                </MenuItem>
              </a>
              <MenuDivider />
            </MenuList>
          </Menu>
        </Show>
      </Flex>
    </Flex>
  );
};
export default Navbar;
