import Navbar from "../components/Navbar/Navbar";
import Hero from "../components/Hero/Hero";
import Unique from "../components/Unique/Unique";
import Services from "../components/Services/Services";
import Navigation from "../components/Navigation/Navigation";
import { Helmet } from "react-helmet";
import { Button, Flex, Heading, Hide, Show, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Companies from "../components/Companies/Companies";
import ApplyNow from "../components/ApplyNow/ApplyNow";

const Scroll = () => {
  return (
    <Hide above='sm'>
      <Show below='md'>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          w='100%'
          dir='column'
        >
          <Text fontSize='lg' fontWeight={"bold"} color='#185C81'>
            Scroll For More
          </Text>
          <ChevronDownIcon />
        </Flex>
      </Show>
    </Hide>
  );
};

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Webeet.io | Home</title>
      </Helmet>
      <Navbar path='/' />
      <Flex direction={"column"} alignItems={"center"} w='100%'>
        <Hero />
        <Scroll />
        <Unique />
        <Scroll />
        <Services />
        <Scroll />
      </Flex>
      <Companies />
      <ApplyNow />
      <Navigation path='/' />
    </>
  );
};

export default Home;
