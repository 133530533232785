import {
  magicYard,
  merryPopin,
  mindguard,
  noLedgeloss,
  torontoUniversity,
} from "../assets/companies";
import { lior, chen, yair, enas, reut } from "../assets/testimonials";

export const projects = [
  {
    image: mindguard,
    alt: "Mind Guard",
    title: "Mind Guard",
    content: [
      "Mind Guard is a groundbreaking mobile application designed to support mental health and well-being through AI-driven solutions. Developed as part of our program, this project leverages cutting-edge technologies to create a platform that helps users monitor, understand, and improve their mental health in meaningful ways.",
      "Participants collaborated with industry professionals to design and implement features such as mood tracking, personalized wellness recommendations, and AI-powered virtual assistance. The app integrates machine learning algorithms to analyze user inputs and provide actionable insights, ensuring a tailored and impactful experience for every user.",
      "This project not only reflects our commitment to creating socially impactful technology but also exemplifies the power of real-world learning. By contributing to Mind Guard, our participants honed skills in mobile development, AI integration, and user experience design—while making a meaningful difference in an essential area of modern life.",
    ],
    link: "https://getmindguard.com/",
    testimonial: {
      name: "Yair Nativ",
      position: "Founder & CEO",
      company: "Mind Guard",
      avatar: yair,
      content:
        "Webeet.io has been a vital partner in the development of Mind Guard. Their work on integrating AI-driven productivity tools into our platform was both visionary and practical. From creating seamless UX/UI designs to ensuring the backend was optimized for performance, their contributions have directly impacted the success of our product. Their team’s mentorship and collaborative spirit were invaluable as we worked to bring our AI-powered solution to market.",
    },
  },
  {
    image: noLedgeloss,
    alt: "NoledgeLoss",
    title: "NoledgeLoss",
    link: "https://getmindguard.com/",
    content: [
      "Noledgeloss is an innovative solution created to tackle the growing problem of organizational knowledge retention. This project focuses on preserving critical information within teams and businesses by creating a centralized platform for documentation, collaboration, and knowledge sharing. Noledgeloss ensures that valuable insights and expertise are never lost, even as teams evolve.",
      "Participants on this project worked on advanced features like automated knowledge tagging, intelligent search, and secure cloud integration. The team applied their technical expertise to develop a platform that is not only functional but also user-friendly, ensuring seamless adoption across industries.",
      "By contributing to Noledgeloss, participants gained invaluable experience in backend development, data structuring, and user experience design. This project exemplifies how technology can solve pressing business challenges while providing participants with the real-world skills they need to succeed in their careers.",
    ],
    testimonial: {
      name: "Enas Awwad",
      position: "Co-Founder & CTO",
      company: "NoLedgeloss",
      avatar: enas,
      content:
        "The team at webeet.io played an instrumental role in bringing our vision for NoLedgeloss to life. They didn’t just build a platform; they developed advanced features like knowledge tagging and intelligent search that truly transformed how our system works. Their dedication to understanding our needs and their ability to deliver innovative, scalable solutions have positioned us as leaders in knowledge retention technology. Their impact on our product and team was extraordinary.",
    },
  },
  {
    image: magicYard,
    alt: " Magic Yard",
    title: "Magic Yard",
    link: "https://magicyard.co/",
    content: [
      "We partnered with Magic Yard, a groundbreaking platform that transforms TVs into social gaming hubs, bringing people closer through exciting board games no matter where they are. Their innovative mission to connect players worldwide aligns seamlessly with our program's dedication to tackling real-world challenges through cutting-edge technology and creativity.",
      "Our team worked on two unique games for the platform, managing every aspect of development from concept to execution. We implemented complex game logic to ensure smooth, immersive gameplay and designed intuitive, visually appealing user interfaces that enhance the gaming experience.",
      "This collaboration provided participants with invaluable real-world experience in game development, platform integration, and user experience design. By contributing to Magic Yard’s mission, our team played a key role in revolutionizing how people connect and have fun through technology, bringing social gaming to life in an entirely new way.",
    ],
    testimonial: {
      name: "Lior Sabag",
      position: "Co-Foudner & CTO",
      company: "Mind Guard",
      avatar: lior,
      content:
        "Collaborating with webeet.io on Magic Yard was an incredible experience. They handled every aspect of the development for two of our games, from implementing game logic to designing a seamless user interface. Their integration of a new gaming library into our platform was flawless, and their attention to detail ensured everything worked perfectly on our TV-based system. Their ability to combine technical expertise with a deep understanding of user experience has been a key factor in our success.",
    },
  },
  {
    image: merryPopin,
    alt: "Merry Popin",
    title: "Merry Popin",
    link: "https://merrypopin.com/",
    content: [
      "Merry Popin is an innovative platform designed to enhance productivity and streamline collaboration in both personal and professional settings. This project focuses on simplifying workflows with intuitive task management, smart scheduling, and seamless team coordination, helping users stay organized and achieve their goals efficiently.",
      "Participants in this project developed advanced features like AI-driven task prioritization and real-time data synchronization, creating a tool that adapts to the needs of its users. They worked closely with mentors to design a platform that combines functionality with an elegant user interface.",
      "Through Merry Popin, participants gained hands-on experience in creating impactful digital solutions. They refined their skills in software engineering, user-centered design, and agile development, resulting in a tool that empowers users to tackle their tasks with confidence and ease. This project exemplifies the transformative power of technology in improving daily productivity.",
    ],
    testimonial: {
      name: "Chen Vazana",
      position: "Co-Founder & COO",
      company: "Merry Popin",
      avatar: chen,
      content:
        "When Merry Popin was just an idea, webeet.io helped us transform it into a fully functional platform that exceeded our expectations. They developed AI-driven task prioritization tools and designed an intuitive, beautiful user interface that our customers love. Their collaborative approach and commitment to delivering quality have been instrumental in turning Merry Popin into a powerful productivity tool. We couldn’t have done it without them.",
    },
  },
  // {
  //   image: anyway,
  //   alt: "Anyway",
  //   title: "Anyway",
  //   link: "https://magicyard.co/",
  //   content: [
  //     "Anyway is a transformative platform focused on enhancing urban mobility and safety through innovative navigation solutions. Their mission is to provide users with smarter, more efficient routes that prioritize safety, sustainability, and accessibility. Our collaboration with Anyway exemplifies how technology can make a meaningful difference in the way we navigate and experience cities.",
  //     "Our team contributed by integrating advanced features into their navigation system, including real-time data processing for traffic and hazard detection, as well as user-friendly interfaces for seamless interaction.",
  //     "This project provided participants with invaluable experience in tackling large-scale, data-driven challenges. From advanced backend development to designing interfaces that enhance the user experience, the collaboration with Anyway reflects the real-world impact of combining innovation with purpose-driven technology. It’s a step forward in shaping safer and more connected urban environments.",
  //   ],
  // },
  {
    image: torontoUniversity,
    alt: "University of Toronto",
    title: "University of Toronto",
    content: [
      "We partnered with the University of Toronto’s Ophthalmology Labs on an innovative project that integrates cutting-edge AI technology into a global platform for eye disease detection. This revolutionary AI, developed by their researchers, analyzes medical images to identify conditions like retinal diseases with precision, providing doctors worldwide with an advanced tool for early diagnosis and treatment planning.",
      "Our team worked on integrating the AI into a secure, user-friendly platform tailored for ophthalmologists. The platform enables healthcare professionals to upload and analyze patient eye images quickly, delivering accurate assessments powered by AI.",
      "This collaboration underscores the transformative potential of technology in healthcare, enabling earlier detection and better outcomes for patients on a global scale. It was an opportunity to apply their skills to a meaningful project, gaining experience in AI integration, and the intersection of technology and medicine.",
    ],
    link: "https://ophthalmology.utoronto.ca/faculty/rajeev-muni",
    testimonial: {
      name: "MD Reut Shor",
      position: "Head of AI Integration Project",
      company: "University of Toronto",
      avatar: reut,
      content:
        "The work webeet.io did with the University of Toronto’s Ophthalmology Labs was truly groundbreaking. By integrating our AI technology into a global platform for eye disease detection, they helped create a tool that enables doctors worldwide to diagnose retinal conditions more efficiently. Their expertise in backend development and secure AI integration was matched by their ability to design a user-friendly interface for healthcare professionals. They brought this vision to life with skill and precision, making a real impact on global health.",
    },
  },
];

export const projectGradients = [
  "linear(to-b, #B9B0E8, rgba(185, 176, 232, 0.3))",
  "linear(to-b, #D9EDF8, rgba(217, 237, 248, 0.3))",
  "linear(to-b, #FFADAD, rgba(255, 173, 173, 0.3))",
  "linear(to-b, #FFD6A5, rgba(255, 214, 165, 0.3))",
  "linear(to-b, #E4F1EE, rgba(228, 241, 238, 0.3))",
  "linear(to-b, #a5ffdb, rgba(165, 255, 219, 0.3))",
];

export const buttonColors = [
  "#B9B0E8",
  "#D9EDF8",
  "#FFADAD",
  "#FFD6A5",
  "#E4F1EE",
];
