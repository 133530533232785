import {
  Box,
  Image,
  Heading,
  useBreakpointValue,
  Flex,
  Text,
} from "@chakra-ui/react";
import {
  h25,
  ceevee,
  magicYard,
  merryPopin,
  menow,
  qelos,
  srisa,
  formelio,
  mindguard,
} from "../../assets/companies";

import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

const Companies = ({ size = "150px" }) => {
  const slidesPerView = useBreakpointValue({
    base: 1,
    sm: 1,
    md: 3,
    lg: 4,
    xl: 5,
  });

  const companies = [
    {
      image: mindguard,
      alt: "Mind Guard",
      url: "/project/mind guard",
      isWhite: false,
    },
    {
      image: qelos,
      alt: "Qelos",
      url: "/projects",
      isWhite: false,
    },
    {
      image: h25,
      alt: "Hour25",
      url: "/projects",
      isWhite: false,
    },
    {
      image: ceevee,
      alt: "ceevee.ai",
      url: "/projects",
      isWhite: false,
    },
    {
      image: magicYard,
      alt: "Magic Yard",
      url: "/project/magic yard",
      isWhite: false,
    },
    {
      image: menow,
      alt: "MeNow",
      url: "/projects",
      isWhite: true,
    },
    {
      image: merryPopin,
      alt: "Merry Popin",
      url: "/project/merry popin",
      isWhite: false,
    },
    {
      image: srisa,
      alt: "Srisa",
      url: "/projects",
      isWhite: true,
    },
    {
      image: formelio,
      alt: "Formelio",
      url: "/projects",
      isWhite: false,
    },
  ];

  return (
    <Box as='section' my={12} id='work'>
      <Heading textAlign={"center"} mb={20}>
        Working With Us
      </Heading>
      <Swiper
        slidesPerView={slidesPerView}
        autoplay={{ delay: 2000, pauseOnMouseEnter: true }}
        pagination={{ clickable: true, dynamicBullets: true }}
        loop
        centeredSlides
      >
        {companies.map((company) => (
          <SwiperSlide key={company.alt}>
            <Flex justify='center' align='center' flexDir='column'>
              <Link to={company.url} key={company.alt}>
                <Image
                  boxSize={size}
                  objectFit='contain'
                  src={company.image}
                  alt={company.alt}
                  p={company.isWhite ? [1, 3] : ""}
                  bg={company.isWhite ? "#333" : ""}
                  borderRadius={company.isWhite ? "xl" : ""}
                />
              </Link>
              <Text fontSize={"lg"} fontWeight={"semibold"} mt={4}>
                {company.alt}
              </Text>
            </Flex>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default Companies;
