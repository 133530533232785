import { Button, Flex, Heading } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const ApplyNow = () => {
  return (
    <Flex
      w='100%'
      direction='column'
      mt={10}
      alignItems='center'
      justifyContent='center'
    >
      <Heading mb={10} as='h2'>
        Ready To Succeed?
      </Heading>
      <a
        href='https://form.typeform.com/to/rQCjNxVX'
        target='_blank'
        rel='noopener noreferrer'
      >
        <Button bgColor='#B9B0E8' color='white'>
          Let's Collaborate
        </Button>
      </a>
    </Flex>
  );
};

export default ApplyNow;
