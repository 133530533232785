import { CheckIcon, SettingsIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { webeet } from "../assets/companies";
import { FaMoneyBill1 } from "react-icons/fa6";

const links = {
  webeet: "https://form.typeform.com/to/W1smuOPM",
};

const what_we_offer = {
  title: "What We Offer",
  text: "This collaboration aims to ensure that all graduates secure a full-time job before the end of the program. You will gain practical experience, refine your skills, and be fully prepared for your next career step!",
  icon: FaMoneyBill1,
  bulletPoints: [
    "6-month part-time paid role (4 hours/day).",
    "Close mentorship from a senior software engineer to guide you through hands-on learning.",
    "A collaborative and innovative environment where independence and creativity are highly valued.",
    "Career development support, including personalized learning guidance.",
    "interview preparation with webeet.io team leads",
  ],
};

export const applications = [
  {
    id: "diosa7958a7isdjkhaskjfhgkajshdkasjh",
    company: "webeet.io",
    image: webeet,
    domain: "data",
    job: "Data Analyst",
    applicationLink: links.webeet,
    description:
      "Step into an exciting role where your analytical skills help shape the future of data-driven decision-making.",
    content: [
      {
        title: "About The Role",
        text: "Step into an exciting role where your analytical skills help shape the future of data-driven decision-making. As a Junior Data Analyst, you’ll dive into diverse datasets, uncovering valuable insights into user behavior, engagement trends, and emerging patterns that drive impactful change. Collaborate with dynamic, cross-functional teams to enhance AI tools and innovative product offerings while honing your expertise in Python, SQL, and Tableau. This role empowers you to transform raw data into compelling stories, crafting dashboards and visualizations that influence key business strategies. With opportunities to learn cutting-edge statistical methods, machine learning techniques, and advanced data tools, this position offers a perfect blend of growth, creativity, and impact in the ever-evolving world of analytics.",
      },
      {
        title: "Key Responsibilities",
        icon: CheckIcon,
        bulletPoints: [
          "Analyze datasets from various platforms to derive insights related to user behavior, stress detection, and engagement patterns.",
          "Work closely with cross-functional teams, contributing to the improvement of AI tools and product offerings.",
          "Utilize SQL and Python for data extraction, analysis, and visualization.",
          "Create reports and dashboards using tools like Tableau to present findings to key stakeholders.",
          "Continuously learn and apply statistical methods and machine learning processes as you grow in the role.",
        ],
      },
      {
        title: "Required Skills",
        icon: SettingsIcon,
        bulletPoints: [
          "Basic knowledge of Python for data manipulation and analysis.",
          "Proficiency in SQL for querying and managing databases.",
          "Familiarity with Tableau or similar data visualization tools.",
          "Completion of a Data Analytics program",
        ],
      },
      {
        title: "Willingness to Learn",
        icon: InfoOutlineIcon,
        bulletPoints: [
          "statistical methods and machine learning processes.",
          "Data visualization tools (e.g., PowerBI, Looker).",
          "Data processing frameworks (e.g., Pandas, NumPy).",
          "Machine learning models and behavioral analysis.",
        ],
      },
      what_we_offer,
    ],
  },
  {
    id: "45daisuoitlualweiad7poasidkljahs",
    company: "webeet.io",
    image: webeet,
    domain: "software",
    job: "Software Engineer",
    description:
      "Join a fast-paced environment where your creativity and technical skills will directly shape innovative solutions.",
    applicationLink: links.webeet,
    content: [
      {
        title: "About The Role",
        text: "Join a fast-paced environment where your creativity and technical skills will directly shape innovative solutions. As a Junior Software Engineer, you’ll develop and refine software that integrates with cutting-edge AI and ML tools, enhancing user experiences and pushing the boundaries of performance. Collaborate with talented cross-functional teams to design data-driven features and create seamless applications for both client and server sides. Dive into dynamic projects using modern technologies like JavaScript, Python, and Node.js while working with databases like MongoDB or PostgreSQL. With ample opportunities to learn, grow, and make a real impact, this role is perfect for ambitious engineers eager to transform ideas into reality while building a solid foundation in best practices and advanced tools.",
      },
      {
        title: "Key Responsibilities",
        icon: CheckIcon,
        bulletPoints: [
          "Develop and maintain software solutions that integrate with ML and AI tools, improving user experiences and performance.",
          "Collaborate closely with cross-functional teams to optimize data-driven features.",
          "Write clean, efficient, and maintainable code on the client and server side.",
          "Work with databases for data management and storage solutions.",
          "Continuously learn and apply best practices in software engineering as you grow in the role.",
        ],
      },
      {
        title: "Required Skills",
        icon: SettingsIcon,
        bulletPoints: [
          "proficiency in at least one programming language (e.g., JavaScript, Python).",
          "Knowledge of Restful APIs, Web Architecture and HTTP methods.",
          "Knowledge of version control systems (e.g., Git) for collaborative development.",
          "Experience with databases like MongoDB or PostgreSQL.",
          "Completion of Masterschool's software engineering program",
        ],
      },
      {
        title: "Willingness to Learn",
        icon: InfoOutlineIcon,
        bulletPoints: [
          "JavaScript and TypeScript, React and React Native.",
          "Backend development with Node.js (Express.js)",
          "AI and ML integration processes",
          "Python, libraries and tools for AI model integration.",
          "Cloud services like AWS for deployment and scaling.",
        ],
      },
      what_we_offer,
    ],
  },
  {
    id: "45daisu074398iuehsda9ykhdsidkljahs",
    company: "webeet.io",
    image: webeet,
    domain: "security",
    job: "Security Analyst",
    description:
      "Monitor security threats and vulnerabilities across platforms, ensuring timely detection and response.",
    applicationLink: links.webeet,
    content: [
      {
        title: "About The Role",
        text: "Embark on an exciting journey where you’ll be the first line of defense in safeguarding digital ecosystems. As a Junior Security Analyst, you’ll play a vital role in monitoring and mitigating security threats, ensuring the safety of critical platforms. Beyond detecting vulnerabilities, you’ll take a proactive approach to secure coding, conduct quality assurance testing, and build resilient incident response plans that minimize downtime and disruptions. Collaborate with experts to adapt cutting-edge security tools and techniques, gaining hands-on experience in areas like cloud security and threat detection. This role offers an incredible opportunity to grow as a cybersecurity professional while making a real impact in the fight against evolving digital threats.",
      },
      {
        title: "Key Responsibilities",
        icon: CheckIcon,
        bulletPoints: [
          "Monitor security threats and vulnerabilities across platforms, ensuring timely detection and response.",
          "Audit code for security risks, identifying vulnerabilities and ensuring compliance with best practices.",
          "Conduct security-focused quality assurance (QA) testing to verify the effectiveness of protective measures.",
          "Develop incident response plans and cases to ensure minimal downtime and effective recovery processes.",
          "Provide IT support, manage user access, and enforce secure access control policies.",
        ],
      },
      {
        title: "Required Skills",
        icon: SettingsIcon,
        bulletPoints: [
          "Understanding of common cybersecurity threats, including malware, phishing, and network vulnerabilities.",
          "Basic knowledge of code auditing practices and secure coding standards.",
          "Familiarity with security tools for monitoring and threat detection (e.g., SIEM tools).",
          "Willingness to learn and adapt to new security technologies and best practices.",
          "Completion of a cybersecurity program or bootcamp (no formal degree required).",
        ],
      },
      {
        title: "Willingness to Learn",
        icon: InfoOutlineIcon,
        bulletPoints: [
          "Scripting languages (e.g., Python, Bash) for automation of security tasks.",
          "Version control systems (e.g., Git) for tracking code changes and audits.",
          "Cloud security concepts (e.g., AWS) and managing access control in cloud environments.",
          "Incident response frameworks and disaster recovery strategies.",
        ],
      },
      what_we_offer,
    ],
  },
];
