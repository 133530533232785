export const faqs = [
  {
    question:
      "What makes this program different from traditional training programs?",
    answer:
      "This is an advanced program, that offers participants the opportunity to work on real-world projects, gain hands-on experience, and earn a salary. This unique approach ensures that participants develop the skills and knowledge needed to succeed in the tech industry.",
  },
  {
    question: "Who is eligible to join the program?",
    answer:
      "The program is designed for talented and motivated junior professionals, typically recent graduates or those with foundational skills in software engineering, data analytics, or cybersecurity. If you’re eager to gain real-world experience and take the next step in your career, this program is for you.",
  },
  {
    question: "What kinds of projects will I work on?",
    answer:
      "Participants work on a variety of real-world projects across domains such as software engineering, AI integration, data analytics, and cybersecurity. These projects often involve building solutions for startups and established companies, providing valuable industry experience.",
  },
  {
    question: "Is this program remote or in-person?",
    answer:
      "Our program is fully remote, making it accessible to participants from around the globe. This flexibility allows you to gain hands-on experience and mentorship from anywhere while collaborating with industry experts and peers.",
  },
  {
    question: "Do participants get paid?",
    answer:
      "Yes! of the key features of our program is that participants earn a salary while working on real-world projects. This ensures you gain not only experience but also financial support as you develop your career.",
  },
  {
    question: "What kind of support do participants receive?",
    type: "list",
    answer: "Participants benefit from:",
    list: [
      "Mentorship by experienced senior professionals.",
      "Career development resources, including portfolio-building and interview preparation.",
      "Feedback and guidance throughout every stage of their",
    ],
  },
  {
    question: "What industries or technologies are participants exposed to?",
    answer: "Our projects span cutting-edge domains, including:",
    type: "list",
    list: [
      "Software engineering",
      "Data analytics",
      "Cybersecurity",
      "AI and ML integration",
    ],
  },
  {
    question: "How long does the program last?",
    answer:
      "The program is designed to be completed within a maximum of 6 months. However, 95% of participants secure a job before the program even ends, thanks to the hands-on experience and industry connections they gain.",
  },
];
