import {
  Box,
  Flex,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import newWave from "../../assets/layout/new-waves.svg";

const Unique = () => {
  return (
    <Flex
      minW='100%'
      minH={["1600px", "1350px", "1250px", "1250px", "1350px"]}
      bgImage={newWave}
      backgroundSize='cover'
      backgroundPosition='center'
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box
        as='section'
        maxW={["100%", "100%", "80%", "80%", "50%"]}
        mx={[10, 10, 10, 10, "auto"]}
      >
        <Heading mb={4}>What Makes Us Different</Heading>
        <Text>
          At webeet.io, we deliver exceptional digital solutions through a
          unique and efficient model:
        </Text>
        <UnorderedList>
          <ListItem fontSize='lg' my={4}>
            <Box fontWeight={"bold"}>Expert Senior Oversight:</Box>
            Our senior developers, with over 10 years of experience, are not
            just technical experts—they’re proven team leads and mentors. They
            oversee every aspect of your project, from architecture to
            execution, ensuring both technical excellence and team success.
            Their leadership guarantees that even the most complex projects are
            delivered on time and exceed expectations.
          </ListItem>
          <ListItem fontSize='lg' my={4}>
            <Box fontWeight={"bold"}>Driven Junior Talent:</Box> Exceptional We
            collaborate with top-tier bootcamps to recruit the brightest junior
            developers. These individuals are at the top of their class,
            bringing fresh perspectives, up-to-date skills, and a hunger to
            innovate. Under the mentorship of our senior developers, they work
            diligently to transform your vision into a high-quality product.
          </ListItem>
          <ListItem fontSize='lg' my={4}>
            <Box fontWeight={"bold"}>Specialized Talent on Demand:</Box>
            To meet the unique needs of every project, we bring in specialized
            professionals as required. Whether it’s cloud architects, machine
            learning engineers, designers, or other niche experts, we assemble
            the perfect team to ensure your project’s success.
          </ListItem>
        </UnorderedList>
        <Text fontSize={"lg"}>
          Our model balances premium expertise with affordability, allowing us
          to deliver top-notch solutions at startup-friendly prices. This
          ensures that you get the best of both worlds: exceptional quality and
          efficient use of resources.
        </Text>
      </Box>
    </Flex>
  );
};

export default Unique;
