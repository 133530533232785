import {
  Box,
  Heading,
  Text,
  Flex,
  UnorderedList,
  ListItem,
  Button,
} from "@chakra-ui/react";

import { purpleWaves } from "../../assets/layout/index";
import { Link } from "react-router-dom";

const Navigation = ({ path }) => {
  const year = new Date().getFullYear();

  return (
    <Box
      bgImage={purpleWaves}
      backgroundSize='cover'
      backgroundPosition='top'
      display={"flex"}
      alignItems={"center"}
      width='100%'
      h='650px'
    >
      <Flex
        flexDir={"column"}
        w={"100%"}
        justifyContent={"flex-end"}
        h={"100%"}
        m={[6, 6, 8, 10, 12]}
        gap={[10, 10, 20, 20, "20%"]}
      >
        <Flex
          flexDir={["column", "column", "row", "row", "row"]}
          justifyContent={["flex-start", "space-around"]}
          gap={[6, 6, 10, 12, 40]}
          w={"100%"}
        >
          <Flex direction='column'>
            <Heading fontSize='md' mb={2}>
              Quick Links
            </Heading>
            <UnorderedList>
              <ListItem
                _hover={{ textDecoration: "underline" }}
                textDecoration={path === "/" ? "underline" : ""}
              >
                <Link to='/'>Home</Link>
              </ListItem>
              <ListItem
                _hover={{ textDecoration: "underline" }}
                textDecoration={path === "/team" ? "underline" : ""}
              >
                <Link to='/team'>Team</Link>
              </ListItem>
              <ListItem
                _hover={{ textDecoration: "underline" }}
                textDecoration={path === "/projects" ? "underline" : ""}
              >
                <Link to='/projects'>Case Studies</Link>
              </ListItem>
              <ListItem
                _hover={{ textDecoration: "underline" }}
                textDecoration={path === "/careers" ? "underline" : ""}
              >
                <Link to='/careers'>Careers</Link>
              </ListItem>
            </UnorderedList>
          </Flex>
          <Flex direction='column'>
            <Heading fontSize='md' mb={2}>
              Contact Information
            </Heading>
            <UnorderedList>
              <ListItem>
                <Flex display='flex' gap={2}>
                  <Text>Contact: </Text>
                  <a
                    style={{ color: "blue", textDecoration: "underline" }}
                    href='mailto:Support@webeet.io'
                    target='_blank'
                    rel='noreferrer nofollow'
                  >
                    Support@webeet.io
                  </a>
                </Flex>
              </ListItem>
              <ListItem>
                <Flex display='flex' gap={2}>
                  <Text>Follow Us: </Text>
                  <a
                    style={{ color: "blue", textDecoration: "underline" }}
                    href='https://www.linkedin.com/company/webeet'
                    target='_blank'
                    rel='noreferrer nofollow'
                  >
                    LinkedIn
                  </a>
                </Flex>
              </ListItem>
              <ListItem>
                <Flex display='flex' gap={2}>
                  <Text>Articles: </Text>
                  <a
                    style={{ color: "blue", textDecoration: "underline" }}
                    href='https://david-l-r.medium.com/'
                    target='_blank'
                    rel='noreferrer nofollow'
                  >
                    Read More
                  </a>
                </Flex>
              </ListItem>
              <ListItem>
                <Flex display='flex' gap={2}>
                  <Text>Location: Amsterdam, The Netherlands</Text>
                </Flex>
              </ListItem>
            </UnorderedList>
          </Flex>
          <Flex
            direction='column'
            maxWidth={["100%", "50%", "30%", "25%", "25%"]}
          >
            <Heading fontSize='md' mb={2}>
              Mission
            </Heading>
            <Text maxWidth={["100%", "400px"]}>
              Empowering startups with innovative digital solutions by blending
              expert talent and startup-friendly pricing.
            </Text>
            <Flex w='100%' justifyContent='center'>
              <Button
                mt={4}
                as='a'
                href='https://form.typeform.com/to/rQCjNxVX'
                target='_blank'
                rel='noreferrer nofollow'
                color='white'
                bg='purple.500'
                _hover={{ bg: "purple.600" }}
                _active={{ bg: "purple.700" }}
              >
                Let's Collaborate
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Flex mb={[2, 4, 8, 10, 12]} mx='auto' fontWeight={"bold"}>
          <Text fontSize='sm'>© {year} webeet. All rights reserved.</Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Navigation;
