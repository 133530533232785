import Navbar from "../components/Navbar/Navbar";
import { Steps, Step } from "chakra-ui-steps";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  Image,
  ListItem,
  Mark,
  Text,
  UnorderedList,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  OrderedList,
} from "@chakra-ui/react";
import React from "react";
import { applications } from "../data/applications";
import { Helmet } from "react-helmet";
import Navigation from "../components/Navigation/Navigation";
import {
  masterschool,
  iron_hack,
  le_wagon,
  tripleten,
} from "../assets/bootcamps";
import { buttonColors, projectGradients } from "../data/projects";
import { Link } from "react-router-dom";
import { graduates } from "../data/graduates";
import { faqs } from "../data/faqs";

const Logo = ({ src, alt }) => (
  <Image src={src} alt={alt} width='60px' height='60px' objectFit='contain' />
);

export const ApplicationCard = ({ application, index }) => {
  return (
    <Flex
      bgGradient={projectGradients[index % projectGradients.length]}
      h={"40%"}
      p={10}
      mt={10}
      borderRadius={"40px"}
      width={"500px"}
      maxWidth={"100%"}
      direction={"column"}
      justifyContent={"space-between"}
    >
      <Heading size='md' display='flex' mb={8}>
        Junior {application.job}
      </Heading>
      <Text mb={8}>{application.description}</Text>
      <Flex justifyContent='flex-end' w='100%'>
        <Link to={`/application/${application.id}`}>
          <Button
            bg={buttonColors[index % buttonColors.length]}
            color='#333'
            _hover={{ bg: "#185C81", color: "white" }}
            _active={{ bg: "#185C81", color: "white" }}
          >
            Read More
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
};

export const GraduateCard = ({ graduate, index }) => (
  <Card mt={8} shadow={"lg"}>
    <CardHeader textAlign={"center"} pb={0} fontSize={"4xl"}>
      ״
    </CardHeader>
    <CardBody py={0}>{graduate.content}</CardBody>
    <CardBody>
      <Text fontWeight={"bold"} fontStyle={"Italic"}>
        Found a job in {graduate.timeToJob}
      </Text>
    </CardBody>
    <CardFooter>
      <Flex dir='row' alignItems={"center"}>
        <Image
          fit='cover'
          width={"40px"}
          height={"40px"}
          borderRadius={"100%"}
          src={graduate.avatar}
          alt={`${graduate.name} | ${graduate.position}`}
          mr={[2, 4]}
        />
        <Box>
          <Text fontWeight='bold'>{graduate.name}</Text>
          <Text>
            {graduate.position} | {graduate.company}
          </Text>
        </Box>
      </Flex>
    </CardFooter>
  </Card>
);

const Apply = () => {
  const applySteps = [
    {
      label: "Submit Your Application",
      content:
        "Include your resume and proof of bootcamp completion (e.g., diploma).",
    },
    {
      label: "Pass the Assessment",
      content:
        "Showcase your skills through a practical task designed to reflect the challenges you’ll tackle at webeet.io.",
    },
    {
      label: "Join the Team",
      content:
        "Once accepted, you’ll start working on industry projects, earning a salary and advancing your skills.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Webeet.io | Careers</title>
      </Helmet>
      <Navbar path='/careers' />
      <Flex maxW='100%' justifyContent={"center"}>
        <Flex flexDir='column' maxW={"100%"} width={"600px"} p={6}>
          <Heading
            textTransform='uppercase'
            color='#185C81'
            fontWeight='black'
            textAlign={"center"}
            width='100%'
            mt={[8, 12, 16, 20, 24]}
            as='h1'
          >
            Careers
          </Heading>

          <Text lineHeight={8} mt={8} fontSize='lg'>
            At webeet.io, we’re not just offering you a job—we’re providing a
            transformative opportunity to launch your career in tech. Our
            program is the next generation of training, combining real-world
            industry projects, mentorship from experienced professionals, and
            structured learning to help you succeed.
          </Text>
          <Text lineHeight={8} mt={8} fontSize='lg'>
            We’re proud to share that 85% of our junior participants secure jobs
            within 6 months, and 95% within 9 months. These results aren’t just
            numbers—they’re a testament to our commitment to nurturing talent
            and providing the tools you need to thrive.
          </Text>
          <Heading as='h2' fontSize={"2xl"} mt={8}>
            Why Choose Webeet.io
          </Heading>
          <UnorderedList mt={4} spacing={4}>
            <ListItem fontSize='lg'>
              <Heading as='h3' fontSize={"lg"} mb={4}>
                Work on Real Industry Projects
              </Heading>
              Our studio is responsible for delivering high-quality solutions to
              clients worldwide. You’ll contribute to innovative, real-world
              projects in software engineering, AI integration, data analytics,
              and cybersecurity.
            </ListItem>
            <ListItem fontSize='lg'>
              <Heading as='h3' fontSize={"lg"} mb={4}>
                Work on Real Industry Projects
              </Heading>
              Our studio is responsible for delivering high-quality solutions to
              clients worldwide. You’ll contribute to innovative, real-world
              projects in software engineering, AI integration, data analytics,
              and cybersecurity.
            </ListItem>
            <ListItem fontSize='lg'>
              <Heading as='h3' fontSize={"lg"} mb={4}>
                Close Mentorship and Guidance
              </Heading>
              You’ll work side-by-side with experienced mentors who are
              dedicated to your growth. From project planning to execution,
              they’ll guide you every step of the way, ensuring you learn and
              excel.
            </ListItem>
            <ListItem fontSize='lg'>
              <Heading as='h3' fontSize={"lg"} mb={4}>
                Earn While You Learn
              </Heading>
              Unlike traditional programs, you’ll receive a competitive salary
              while building your skills and portfolio. We believe in supporting
              our participants both professionally and financially.
            </ListItem>
            <ListItem fontSize='lg'>
              <Heading as='h3' fontSize={"lg"} mb={4}>
                Structured Learning Support
              </Heading>
              In addition to hands-on experience, you’ll receive curated
              learning materials and resources tailored to your role. This
              ensures you not only work on projects but also continuously hone
              your skills.
            </ListItem>
            <ListItem fontSize='lg'>
              <Heading as='h3' fontSize={"lg"} mb={4}>
                Proven Placement Success
              </Heading>
              With partnerships across leading bootcamps and a focus on
              career-readiness, we’ve placed countless graduates in top tech
              roles worldwide.
            </ListItem>
          </UnorderedList>
          <Heading as='h2' fontSize={"2xl"} mt={8}>
            Who Can Apply?
          </Heading>
          <Text lineHeight={8} mt={8} fontSize='lg'>
            We collaborate with talented graduates from leading bootcamps. To
            join our team, you must have successfully completed a training
            program with one of the following programs:
          </Text>
          <Flex
            width={"100%"}
            maxWidth='1200px'
            justifyContent='space-around'
            mt={6}
          >
            <Flex flexDir='column' alignItems='center'>
              <Logo src={masterschool} alt='masterschool' />
              <Text fontSize={"xs"} textAlign={"center"} fontWeight={"bold"}>
                Masterschool
              </Text>
            </Flex>
            <Flex flexDir='column' alignItems='center'>
              <Logo src={tripleten} alt='tripleten' />
              <Text fontSize={"xs"} textAlign={"center"} fontWeight={"bold"}>
                Triple Ten
              </Text>
            </Flex>
            <Flex flexDir='column' alignItems='center'>
              <Logo src={le_wagon} alt='le_wagon' />
              <Text fontSize={"xs"} textAlign={"center"} fontWeight={"bold"}>
                Le Wagon
              </Text>
            </Flex>
            <Flex flexDir='column' alignItems='center'>
              <Logo src={iron_hack} alt='iron_hack' />
              <Text fontSize={"xs"} textAlign={"center"} fontWeight={"bold"}>
                Iron Hack
              </Text>
            </Flex>
          </Flex>
          <Text lineHeight={8} mt={8} fontSize='lg'>
            Your journey with us begins after demonstrating your foundational
            skills through these programs, ensuring you’re ready to dive into
            real-world projects.
          </Text>
          <Heading as='h2' fontSize={"2xl"} mt={8}>
            Hear From Our Graduates
          </Heading>
          {graduates.map((graduate, i) => (
            <GraduateCard graduate={graduate} index={i} />
          ))}
          <Heading as='h2' fontSize={"2xl"} my={8}>
            How To Apply?
          </Heading>
          <Text mt={0} fontSize='lg'>
            Ready to take the next step in your career? It’s simple:
          </Text>
          <OrderedList mt={4} spacing={4}>
            {applySteps.map((step, i) => (
              <ListItem fontSize='lg'>
                <Mark fontWeight={"bold"}>{step.label}: </Mark>
                {step.content}
              </ListItem>
            ))}
          </OrderedList>
          <Heading as='h2' fontSize={"2xl"} my={8}>
            A Commitment to Excellence: Are You Ready?
          </Heading>
          <Text mt={0} fontSize='lg'>
            Joining webeet.io isn’t just a step—it’s a leap into a
            transformative experience that requires dedication, resilience, and
            an unwavering commitment to your future. This program is designed to
            help you achieve your career goals in record time, but success
            doesn’t come easy. To secure a job in just a few months, you’ll need
            to go above and beyond.
          </Text>
          <Heading as='h3' fontSize={"xl"} mt={8}>
            What does this mean?
          </Heading>
          <UnorderedList mt={4} spacing={4}>
            <ListItem fontSize='lg'>
              <Mark fontWeight={"bold"}>Balancing Work and Study: </Mark>You’ll
              be contributing to real-world projects while continuing to learn
              through structured guidance and additional resources.
            </ListItem>
            <ListItem fontSize='lg'>
              <Mark fontWeight={"bold"}>Pursuing Certifications: </Mark>You’ll
              have the opportunity to earn industry-recognized certifications
              (like AWS, GCP, or others) that will make your profile stand out
              to employers.
            </ListItem>
            <ListItem fontSize='lg'>
              <Mark fontWeight={"bold"}>
                Networking with Industry Leaders:{" "}
              </Mark>
              Attending meetups, joining online communities, and connecting with
              professionals in your field will be essential to building your
              career.
            </ListItem>
            <ListItem fontSize='lg'>
              <Mark fontWeight={"bold"}>Proactive Job Applications: </Mark>
              You’ll need to be proactive in applying for roles, refining your
              resume, and showcasing the skills you develop during the program.
            </ListItem>
            <ListItem fontSize='lg'>
              <Mark fontWeight={"bold"}>Going the Extra Mile: </Mark>Whether
              it’s late nights studying, taking feedback constructively, or
              solving challenging problems, you’ll need to bring your A-game
              every day.
            </ListItem>
          </UnorderedList>
          <Text mt={4} fontSize='lg'>
            <Mark fontWeight={"bold"}>This program isn’t for everyone.</Mark>{" "}
            We’re looking for individuals who are not only talented but also
            willing to do whatever it takes to succeed. If you’re ready to
            embrace the hard work and dedication required, we’re here to support
            you every step of the way with mentorship, resources, and
            opportunities that will set you apart.
          </Text>
          <Heading as='h2' fontSize={"2xl"} mt={8}>
            FAQ
          </Heading>
          <Text my={4} fontSize='lg'>
            We understand you may have questions about our unique program,
            projects, and mission. Below are answers to some of the most common
            inquiries. If you have additional questions, feel free to reach
            out—we’re here to help!
          </Text>
          <Accordion allowToggle>
            {faqs.map((faq) => (
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex='1' textAlign='left' fontSize='lg'>
                      {faq.question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text fontSize='lg'>{faq.answer}</Text>
                  {faq.type === "list" && (
                    <UnorderedList mt={4} spacing={4}>
                      {faq.list.map((item) => (
                        <ListItem>{item}</ListItem>
                      ))}
                    </UnorderedList>
                  )}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
          <Heading as='h2' fontSize={"2xl"} mt={8}>
            Do you have what it takes? Apply today
          </Heading>
          <Flex
            width='100%'
            maxWidth='1200px'
            alignItems='center'
            flexDir='column'
          >
            {applications.map((application, i) => (
              <ApplicationCard application={application} index={i} />
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Navigation path={"/careers"} />
    </>
  );
};

export default Apply;
