import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar/Navbar";
import Navigation from "../components/Navigation/Navigation";
import { Flex, Heading, Image, Text } from "@chakra-ui/react";
import ApplyNow from "../components/ApplyNow/ApplyNow";
import { useParams } from "react-router-dom";
import { projects } from "../data/projects";
import { Testimonial } from "../components/Testimonial/Testimonial.tsx";

const Project = () => {
  const { company_name } = useParams();

  console.log(company_name);

  const project = projects.find(
    (project) => project.title.toLowerCase() === company_name.toLowerCase()
  );
  const { avatar, name, position, content } = project.testimonial;

  return (
    <>
      <Helmet>
        <title>
          Webeet.io {"<>"} {project.title}
        </title>
      </Helmet>
      <Navbar />
      <Flex
        w='100%'
        flexDir='column'
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Flex
          flexDir='column'
          alignItems='start'
          gap={4}
          m={[6]}
          maxW={"600px"}
        >
          <Heading
            textTransform='uppercase'
            color='#185C81'
            fontWeight='black'
            textAlign={"center"}
            width='100%'
            mt={[8, 12, 16, 20, 24]}
          >
            {project.title}
          </Heading>
          <Flex justifyContent={"center"} width={"100%"}>
            <Image
              src={project.image}
              alt={project.title}
              boxSize='250px'
              objectFit='contain'
              borderRadius='md'
            />
          </Flex>
          {project.content.map((content, index) => (
            <Text lineHeight={8} mt={8} fontSize='lg'>
              {content}
            </Text>
          ))}
        </Flex>
        <Testimonial
          name={name}
          position={position}
          avatar={avatar}
          content={content}
          index={0}
        />
        <ApplyNow />
      </Flex>
      <Navigation />
    </>
  );
};

export default Project;
