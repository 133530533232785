import {
  Heading,
  Box,
  Card,
  CardBody,
  Text,
  Image,
  SimpleGrid,
  Flex,
} from "@chakra-ui/react";

import idea from "../../assets/illustrations/idea.png";

import { services } from "../../data/content";

const Services = () => {
  return (
    <Box
      as='section'
      maxW='1140px'
      mx={[2, 4, 8, 20]}
      id='services'
      mb={12}
      mt={10}
    >
      <Card variant='filled' bg='#D9EDF8' mb={10}>
        <CardBody
          display='flex'
          alignItems='center'
          p={10}
          flexDir={["column-reverse", "row"]}
          gap={[5, 0]}
        >
          <Box
            maxW={["100%", "50%"]}
            display={"flex"}
            flexDir={["column"]}
            gap={[2, 4]}
          >
            <Heading size='xl'>From Idea to Launch</Heading>
            <Text fontSize='lg'>
              At webeet.io, we’re dedicated to turning your ideas into reality.
            </Text>
            <Heading size='lg' as='h3'>
              Your Spark
            </Heading>
            <Text fontSize='lg'>
              From the initial spark of inspiration to a fully realized product,
              we guide you through every step of the journey. Our team
              collaborates closely with you to define clear goals, establish
              realistic timelines, and outline the essential features.
            </Text>
            <Heading size='lg' as='h3'>
              Defining Scope
            </Heading>
            <Text fontSize='lg'>
              We focus on setting a well-defined scope to ensure your project
              stays on track and achieves its objectives efficiently. Whether
              it’s planning the architecture, managing development cycles, or
              delivering the final product, we provide hands-on support and
              expert guidance throughout the process.
            </Text>
            <Heading size='lg' as='h3'>
              Your Success
            </Heading>
            <Text fontSize='lg'>
              You can be confident that your product will launch seamlessly,
              ready to make an impact. Let us help you transform your ideas into
              successful solutions.
            </Text>
          </Box>
          <Flex maxW='450px' alignItems={"center"}>
            <Image src={idea} boxSize='100%' mx='auto' />
          </Flex>
        </CardBody>
      </Card>
      <Heading textAlign='center' mb={8}>
        Our Services
      </Heading>
      <SimpleGrid gap={8} columns={[1, 1, 2, 2, 2]}>
        {services.map((service, i) => (
          <Service key={i} service={service} />
        ))}
      </SimpleGrid>
    </Box>
  );
};

const Service = ({ service }) => {
  return (
    <Card h='full' variant='outline'>
      <CardBody display={"flex"} flexDir={"column"} gap={[2, 4]}>
        <Flex align={"center"} justify={"space-between"} mb={[10, 0]}>
          <Heading size={["md", "md", "lg", "lg", "lg"]} color='#B9B0E8'>
            {service.title}
          </Heading>
          <Image src={service.image} boxSize='50px' mr='20px' />
        </Flex>
        {service.description.map((d, index) => (
          <Text key={index}>{d}</Text>
        ))}
      </CardBody>
    </Card>
  );
};

export default Services;
