import { Flex, Heading, Image, Text } from "@chakra-ui/react";
import Navbar from "../components/Navbar/Navbar";
import Navigation from "../components/Navigation/Navigation";
import { not_found } from "../assets/404/index";

const NotFound = () => {
  return (
    <Flex
      direction='column'
      min-height='100vh'
      alignItems={"center"}
      maxW='100%'
    >
      <Flex
        flexDir='column'
        align='center'
        maxWidth='100%'
        width='800px'
        mx={6}
      >
        <Navbar />
        <Flex
          flexDir={"column"}
          alignItems={"center"}
          mt={5}
          px={[5, 5, 10, 10, 10]}
          maxW='100%'
        >
          <Heading mb={5} mt={5}>
            Page not found
          </Heading>
          <Text maxW='100%' width={"500px"} textAlign={"center"}>
            We are Sorry for that, but it seems like something went wrong...
          </Text>
          <Image src={not_found} alt='not-found' mt={5} maxW={400} />
        </Flex>
      </Flex>
      <Navigation />
    </Flex>
  );
};

export default NotFound;
