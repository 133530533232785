import React from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { projectGradients } from "../../data/projects";

export const Testimonial = ({ name, position, avatar, content, index }) => {
  return (
    <Flex
      bgGradient={projectGradients[index % projectGradients.length]}
      h={"40%"}
      p={10}
      mt={10}
      borderRadius={"40px"}
      width={"500px"}
      maxWidth={"100%"}
      direction={"column"}
      justifyContent={"space-between"}
    >
      <Text>{content}</Text>
      <Flex dir='row' alignItems={"center"} mt={[4, 6, 8]}>
        <Image
          fit='cover'
          width={"40px"}
          height={"40px"}
          borderRadius={"100%"}
          src={avatar}
          alt={`${name} | ${position}`}
          mr={[2, 4]}
        />
        <Box>
          <Text fontWeight='bold'>{name}</Text>
          <Text>{position}</Text>
        </Box>
      </Flex>
    </Flex>
  );
};
